var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('date-picker',{attrs:{"value":_vm.fromDate,"label":"Datum","dateFormatted":_vm.fromDateFormatted},on:{"changeDate":_vm.changeFromDate}})],1),_c('v-col',[_c('date-picker',{attrs:{"value":_vm.toDate,"label":"t/m","dateFormatted":_vm.toDateFormatted},on:{"changeDate":_vm.changeToDate}})],1),_c('v-col',[_c('search-client',{attrs:{"searchString":_vm.searchClientString,"noResults":false},on:{"setSearchString":_vm.setSearchClientString,"get-results":_vm.selectClient,"clear-input":_vm.clearClient}})],1),_c('v-col',[_c('search-item',{attrs:{"searchString":_vm.searchItemString},on:{"setSearchString":_vm.setSearchItemString,"get-results":_vm.selectItem,"clear-input":_vm.clearItem}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Lotnummer"},model:{value:(_vm.searchLotNumber),callback:function ($$v) {_vm.searchLotNumber=$$v},expression:"searchLotNumber"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-self-center justify-end"},[_c('v-btn-toggle',{attrs:{"active-class":"no-active"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.fetchItemsSalesHistory}},[_vm._v("Verzenden")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.reset}},[_vm._v("Reset")])],1)],1)],1),_c('v-row',[_c('v-card',{attrs:{"width":"100%"}},[_c('v-card-title',[_vm._v("Artikelhistorie")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.itemHeaders,"items":_vm.items,"server-items-length":_vm.itemsLength,"options":_vm.options},on:{"update:options":[function($event){_vm.options=$event},_vm.fetchItemsSalesHistory]},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toDateString(item.date))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.amount,"cancel-text":"Annuleren","save-text":"Opslaan","large":"","persistent":""},on:{"update:returnValue":function($event){return _vm.$set(item, "amount", $event)},"update:return-value":function($event){return _vm.$set(item, "amount", $event)},"save":function($event){return _vm.updateOrderItem(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Aantal"},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", _vm._n($$v))},expression:"item.amount"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.amount)+" ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toCurrency(item.price))+" ")]}},{key:"item.netSubtotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toCurrency(item.netSubtotal))+" ")]}},{key:"item.grossSubtotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toCurrency(item.grossSubtotal))+" ")]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }